<template>
  <section class="container">
    <div class="row">
      <div class="col-12">
        <BaseTable :items="supplierLogs" :fields="fields" :isBusy="isBusy">
          <template v-slot:cell(supplierLogs.timestamp)="data">
            {{ getDateAndTime(data.item.timestamp) }}
          </template>
          <template v-slot:cell(supplierLogs.operationType)="data">
            {{ data.item.operationType }}
          </template>
          <template v-slot:cell(supplierLogs.description)="data">
            {{ data.item.description }}
          </template>
          <template v-slot:cell(supplierLogs.person)="data">
            {{ data.item.person.email }}
          </template>

          <template #cell(data)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              {{
                row.detailsShowing
                  ? $t("companyLogs.hide")
                  : $t("companyLogs.show")
              }}
              {{ $t("companyLogs.details") }}
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              {{ row.item.data }}
            </b-card>
          </template>
        </BaseTable>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTable from "@/modules/global/components/BaseTable";
import i18n from "@/plugins/i18n";
import dateMixin from "@/mixins/dateMixin";

export default {
  name: "SupplierLogs",
  components: {
    BaseTable,
  },
  mixins: [dateMixin],
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "supplierLogs.timestamp",
          label: i18n.t("supplierLogs.Timestamp"),
          sortable: true,
        },
        {
          key: "supplierLogs.operationType",
          label: i18n.t("supplierLogs.operationType"),
          sortable: true,
        },
        {
          key: "supplierLogs.description",
          label: i18n.t("supplierLogs.description"),
          sortable: true,
        },
        {
          key: "supplierLogs.person",
          label: i18n.t("supplierLogs.person"),
          sortable: true,
        },
        {
          key: "data",
          label: i18n.t("supplierLogs.data"),
          sortable: true,
        },
      ],
    };
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
    this.isBusy = true;
    await this.$store.dispatch("supplierVuex/getSupplierLogs", this.id);
    this.isBusy = false;
  },
  computed: {
    id() {
      return this.$route.params.supplierId;
    },
    supplierLogs() {
      var logs = this.$store.getters["supplierVuex/supplierLogs"];
      return logs.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    },
  },
};
</script>

<style>
</style>